import React, { useEffect } from 'react';
import { Typography } from '@/UI/Components/Typography/Typography';
import DownloadAppButton from '@/UI/Components/DownloadAppButton/DownloadAppButton';

const Redirect: React.FC = () => {
	useEffect(() => {
		const handleRedirect = () => {
			try {
				const urlParams = new URLSearchParams(window.location.search);
				const code = urlParams.get('code');
				if (code) {
					window.location.href = `finclu://verify?code=${code}`;
				}
			} catch (err) {
				console.error(err);
			}
		};
		handleRedirect();
	}, []);

	return (
		<div className="bg-[#1E6091]">
			<div className="w-full h-screen flex flex-col justify-center items-center ">
				<div className="flex flex-col items-center justify-center w-full max-w-[80%] md:max-w-[50%] text-center grow">
					<div className="flex-1 flex flex-col items-center justify-between gap-6 bg-white p-5 md:p-10 max-h-[60%] md:max-h-[50%] rounded-lg">
						<div className="w-full flex flex-col gap-2 items-center">
							<div className="w-28 items-center">
								<img src="/black-logo.png" alt="Fincluyente" />
							</div>
							<Typography size={22} weight="bold" color="black">
								Redirigiendo a nuestra app
							</Typography>
						</div>
						<div>
							<div className="w-full text-left mb-1">
								<Typography size={16} weight="semibold" color="black">
									En caso de tener la app de Finclu instalada
								</Typography>
							</div>
							<ul className="flex flex-col gap-2 w-full ml-2 marker:text-white text-left">
								<li>
									<Typography size={16} weight="semibold" color="black">
										1. Presiona abrir con "Finclu"
									</Typography>
								</li>
								<li>
									<Typography size={16} weight="semibold" color="black">
										2. Termina el proceso de registro en la app
									</Typography>
								</li>
							</ul>
						</div>
						<div className="flex flex-col gap-4 items-center">
							<hr className="border-b-2 border-2 w-full" />
							<div className="flex justify-center items-center">
								<DownloadAppButton></DownloadAppButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Redirect;
