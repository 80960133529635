import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LandingLayout from '@/pages/Landing/LandingLayout';
import NotFound from '@/pages/NotFound/NotFound';
import App from './App';
import Support from '@/pages/Support/Support';
import Redirect from '@/pages/Redirect/Redirect';
import TermsConditions from '@/pages/TermsConditions/TermsConditions';
import PrivacyPolicy from '@/pages/PrivacyPolicy/PrivacyPolicy';
import CreditOperation from '@/pages/CreditOperation/CreditOperation';

const Router: React.FC = () => {
	const router = createBrowserRouter([
		{
			path: '/',
			element: <App />,
			errorElement: <NotFound />,
			children: [
				{
					index: true,
					element: <LandingLayout />
				},
				{
					path: 'support',
					element: <Support />
				},
				{
					path: 'terms-conditions',
					element: <TermsConditions />
				},
				{
					path: 'privacy',
					element: <PrivacyPolicy />
				},
				{
					path: 'credit-operation',
					element: <CreditOperation />
				}
			]
		},
		{
			path: 'redirect',
			element: <Redirect />
		}
	]);
	return <RouterProvider router={router} />;
};
export default Router;
